<template>
      <div class="relative-pos grow-custom-1 flex-box flexcol">
          <div id="headpage" class="relative-pos w-100-ps-min bg-white shadow">
              <div class="flex-box flexcol w-100 padding-custom-05">
                  <div class="flex-box flexrow ai-center gap-05">
                      <div class="flex-box flexrow ai-center jc-start">
                        <img :src="myLogo" class="ocy-img-08-no-hover h-50-px" alt="logo">
                      </div>
                      <div class="grow-custom-1">&nbsp;
                      </div>
                  </div>
              </div>
          </div>
          <div id="bodypage" class="relative-pos w-100-ps-min flex-box flexcol ai-center padding-custom-1">
              <div class="relative-pos flex-box flexcol jc-start w-100 wmax-50 rounded shadow">
                  <div class="w-100-ps flex-box flexcol ai-start padding-custom-1 bg-primary">
                        <label class="form-label text-light font-bold font1025rem w-100-ps">FORM REQUEST</label>
                  </div>  
                  <div class="flex-box flexcol padding-custom-1">
                      <div class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Jenis</label>
                              <div class="w-100-ps flex-box flexcol">
                                    <div class="form-floating">
                                        <select v-model = "txtform" id="txtform" class="form-control form-control-lg form-select rounded-0 border-top border-start border-end">
                                            <option value="" disabled>--Pilih salah satu--</option>
                                            <option value="1">Ijin</option>
                                            <option value="2">Lembur</option>
                                            <option value="3">Luar Kota</option>
                                        </select>
                                        <template v-if="checkTxtForm === false">
                                            <label for="txtform" class="form-label text-danger font07rem">Jenis form tidak boleh kosong</label>
                                        </template>
                                        <template v-else>
                                            <label for="txtform" class="form-label text-secondary font07rem">Jenis form</label>
                                        </template>
                                    </div>
                                    <template v-if="txtform === '1'">
                                        <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-custom-05 border-start border-end border-bottom bg-white">
                                            <div class="w-100-ps flex-box flexcol">
                                                <div class="form-check">
                                                    <input v-model = "txtdetail11" value="Cuti" class="form-check-input" type="radio" name="flexRadioDefaultx1" id="flexRadioDefaultx11">
                                                    <label class="form-check-label" for="flexRadioDefaultx11">
                                                        Cuti
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input v-model = "txtdetail11" value="Sakit" class="form-check-input" type="radio" name="flexRadioDefaultx1" id="flexRadioDefaultx12">
                                                    <label class="form-check-label" for="flexRadioDefaultx12">
                                                        Sakit
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input v-model = "txtdetail11" value="Terlambat Datang" class="form-check-input" type="radio" name="flexRadioDefaultx1" id="flexRadioDefaultx13">
                                                    <label class="form-check-label" for="flexRadioDefaultx13">
                                                        Terlambat Datang
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input v-model = "txtdetail11" value="Pulang Awal" class="form-check-input" type="radio" name="flexRadioDefaultx1" id="flexRadioDefaultx14">
                                                    <label class="form-check-label" for="flexRadioDefaultx14">
                                                        Pulang Awal
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input v-model = "txtdetail11" value="Lupa Check-In" class="form-check-input" type="radio" name="flexRadioDefaultx1" id="flexRadioDefaultx15">
                                                    <label class="form-check-label" for="flexRadioDefaultx15">
                                                        Lupa Check-In
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input v-model = "txtdetail11" value="Lupa Check-Out" class="form-check-input" type="radio" name="flexRadioDefaultx1" id="flexRadioDefaultx16">
                                                    <label class="form-check-label" for="flexRadioDefaultx16">
                                                        Lupa Check-Out
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input v-model = "txtdetail11" value="Lupa Absen" class="form-check-input" type="radio" name="flexRadioDefaultx1" id="flexRadioDefaultx17">
                                                    <label class="form-check-label" for="flexRadioDefaultx17">
                                                        Lupa Absen
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input v-model = "txtdetail11" value="Tukar Jam" class="form-check-input" type="radio" name="flexRadioDefaultx1" id="flexRadioDefaultx18">
                                                    <label class="form-check-label" for="flexRadioDefaultx18">
                                                        Tukar Jam
                                                    </label>
                                                </div>
                                            </div>
                                            <template v-if="checkTxtJenis11 === false">
                                                <div class="flex-box flexrow jc-end w-100">
                                                    <label class="form-label text-danger font-italic font07rem">&nbsp;&nbsp;Jenis tidak boleh kosong</label>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else-if="txtform === '2'">
                                        <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-custom-05 border-start border-end border-bottom bg-white">
                                            <div class="w-100-ps flex-box flexcol">
                                                <div class="form-check">
                                                    <input v-model = "txtdetail21" value="Libur" class="form-check-input" type="radio" name="flexRadioDefaultx2" id="flexRadioDefaultx21">
                                                    <label class="form-check-label" for="flexRadioDefaultx21">
                                                        Libur
                                                    </label>
                                                </div>
                                                <div class="form-check">
                                                    <input v-model = "txtdetail21" value="Non-Libur" class="form-check-input" type="radio" name="flexRadioDefaultx2" id="flexRadioDefaultx22">
                                                    <label class="form-check-label" for="flexRadioDefaultx22">
                                                        Non-Libur
                                                    </label>
                                                </div>
                                            </div>
                                            <template v-if="checkTxtJenis21 === false">
                                                <div class="flex-box flexrow jc-end w-100">
                                                    <label class="form-label text-danger font-italic font07rem">&nbsp;&nbsp;Jenis tidak boleh kosong</label>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                    <template v-else-if="txtform === '3'">
                                        <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-custom-05 border-start border-end border-bottom bg-white">
                                            <div class="w-100-ps flex-box flexcol">
                                                <div class="form-check">
                                                    <input v-model = "txtdetail31" value="Luar Kota" class="form-check-input" type="radio" name="flexRadioDefaultx3" id="flexRadioDefaultx31">
                                                    <label class="form-check-label" for="flexRadioDefaultx31">
                                                        Luar Kota
                                                    </label>
                                                </div>
                                            </div>
                                            <template v-if="checkTxtJenis31 === false">
                                                <div class="flex-box flexrow jc-end w-100">
                                                    <label class="form-label text-danger font-italic font07rem">&nbsp;&nbsp;Jenis tidak boleh kosong</label>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                              </div>
                          </div>
                      </div>  
                      <div class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Nama</label>
                              <div class="w-100-ps">
                                <div class="form-floating">
                                    <input v-model = "txtnama" type="text" id="txtnama" class="form-control form-control-lg w-100 rounded" placeholder="Isikan nama" autocomplete="off" required>
                                    <template v-if="checkTxtNama === false">
                                        <label for="txtnama" class="form-label text-danger font07rem">Nama tidak boleh kosong</label>
                                    </template>
                                    <template v-else>
                                        <label for="txtnama" class="form-label text-secondary font07rem">Nama</label>
                                    </template>
                                </div>
                              </div>
                          </div>
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Nomor Pegawai</label>
                              <div class="w-100-ps">
                                <div class="form-floating">
                                    <input v-model = "txtnomor" type="text" id="txtnomor" class="form-control form-control-lg w-100 rounded" placeholder="Isikan nomor pegawai" autocomplete="off" required>
                                    <template v-if="checkTxtNomorLength === false">
                                        <label for="txtnomor" class="form-label text-danger font07rem">Nomor tidak boleh kosong</label>
                                    </template>
                                    <template v-else>
                                        <template v-if="checkTxtNomor === false">
                                            <label for="txtnomor" class="form-label text-danger font07rem">Nomor harus berupa angka</label>
                                        </template>
                                        <template v-else>
                                            <label for="txtnomor" class="form-label text-secondary font07rem">Nomor Pegawai</label>
                                        </template>
                                    </template>
                                </div>
                              </div>
                          </div>
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Jabatan</label>
                              <div class="w-100-ps">
                                    <div class="form-floating">
                                        <!--
                                        <select v-model = "txtjabatan" id= "txtjabatan" class="form-control form-control-lg form-select rounded">
                                            <option value="" disabled>--Pilih salah satu--</option>
                                            <option value="Direktur">Direktur</option>
                                            <option value="Manajer">Manajer</option>
                                            <option value="Administrasi">Administrasi</option>
                                            <option value="Teknik">Teknik</option>
                                            <option value="IT">IT</option>
                                            <option value="Marketing">Marketing</option>
                                            <option value="Gudang">Gudang</option>
                                        </select>
                                        -->
                                        <select v-model = "txtjabatan" id= "txtjabatan" class="form-control form-control-lg form-select rounded">
                                            <option value="" disabled>--Pilih salah satu--</option>
                                            <option value="direktur">Direktur</option>
                                            <option value="wakildirektur">Wakil Direktur</option>
                                            <option value="manageritpr">Manager IT &amp; PR</option>
                                            <option value="manageroperasionalmarketing">Manager Operasional &amp; Marketing</option>
                                            <option value="managerfinanceaccounting">Manager Finance &amp; Accounting</option>
                                            <option value="kepalaenjineering">Kepala Enjineering</option>
                                            <option value="kepalateknisi">Kepala Teknisi</option>
                                            <option value="stafffinanceaccounting">Staff Finance &amp; Accounting</option>
                                            <option value="staffit">Staff IT</option>
                                            <option value="staffenjineeringelektronika">Staff Enjineering &amp; Elektronika</option>
                                            <option value="staffteknisielektrikal">Staff Teknisi Elektrikal</option>
                                            <option value="staffteknisiteknikal">Staff Teknisi Teknikal</option>
                                            <option value="staffmarketing">Staff Marketing</option>
                                            <option value="staffgudang">Staff Gudang</option>
                                        </select>                                        
                                        <template v-if="checkTxtJabatan === false">
                                            <label for="txtjabatan" class="form-label text-danger font07rem">Jabatan mulai tidak boleh kosong</label>
                                        </template>
                                        <template v-else>
                                            <label for="txtjabatan" class="form-label text-secondary font07rem">Jabatan</label>
                                        </template>
                                    </div>
                              </div>
                          </div>
                      </div>   
                      <div class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                                <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Tanggal Mulai</label>
                                <div class="w-100-ps">
                                    <div class="form-floating">
                                        <input v-model = "txtstart" type="datetime-local" id="txtstart" class="form-control form-control-lg w-100 rounded" placeholder="Tanggal mulai" autocomplete="off">
                                        <template v-if="checkTxtStart === false">
                                            <label for="txtstart" class="form-label text-danger font07rem">Tanggal mulai tidak boleh kosong</label>
                                        </template>
                                        <template v-else>
                                            <label for="txtstart" class="form-label text-secondary font07rem">Tanggal Mulai</label>
                                        </template>
                                    </div>
                                </div>
                          </div>
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                                <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Tanggal Selesai</label>
                                <div class="w-100-ps">
                                    <div class="form-floating">
                                        <input v-model = "txtfinish" type="datetime-local" id="txtfinish" class="form-control form-control-lg w-100 rounded" placeholder="Tanggal Selesai" autocomplete="off">
                                        <template v-if="checkTxtStart === false">
                                            <label for="txtfinish" class="form-label text-danger font07rem">Tanggal selesai tidak boleh kosong</label>
                                        </template>
                                        <template v-else>
                                            <label for="txtfinish" class="form-label text-secondary font07rem">Tanggal selesai</label>
                                        </template>
                                        <!--
                                        <input v-model = "txtfinish" type="datetime-local" id="txtfinish" class="form-control form-control-lg w-100 rounded" placeholder="Tanggal Selesai" autocomplete="off">
                                        <label for="txtfinish" class="form-label text-secondary font07rem">Tanggal Selesai</label>
                                        -->
                                    </div>
                                </div>
                            </div>
                      </div>   
                      <div class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Keterangan</label>
                              <div class="w-100-ps">
                                <div class="form-floating">
                                    <input v-model = "txtketerangan" type="text" id="txtketerangan" class="form-control form-control-lg w-100 rounded" placeholder="Isikan keterangan" autocomplete="off" required>
                                    <template v-if="checkTxtKeterangan === false">
                                        <label for="txtketerangan" class="form-label text-danger font07rem">Keterangan tidak boleh kosong</label>
                                    </template>
                                    <template v-else>
                                        <label for="txtketerangan" class="form-label text-secondary font07rem">Keterangan</label>
                                    </template>
                                </div>
                              </div>
                          </div>
                      </div>

                      <!--additionnal-cuti-->
                      <div v-if="((txtdetail11 === 'Cuti') && (txtform === '1'))" class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Tgl. Terakhir Cuti</label>
                              <div class="w-100-ps">
                                <div class="form-floating">
                                    <input v-model = "tglterakhircuti" type="text" id="tglterakhircuti" class="form-control form-control-lg w-100 rounded" placeholder="" autocomplete="off">
                                    <label for="tglterakhircuti" class="form-label text-secondary font07rem">Tgl. Terakhir Cuti</label>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div v-if="((txtdetail11 === 'Cuti') && (txtform === '1'))" class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Sisa cuti tahun berjalan</label>
                              <div class="w-100-ps">
                                <div class="form-floating">
                                    <input v-model = "sisacutithnberjalan" type="text" id="sisacutithnberjalan" class="form-control form-control-lg w-100 rounded" placeholder="" autocomplete="off">
                                    <label for="sisacutithnberjalan" class="form-label text-secondary font07rem">Sisa cuti tahun berjalan</label>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div v-if="((txtdetail11 === 'Cuti') && (txtform === '1'))" class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Jumlah hari yang diambil</label>
                              <div class="w-100-ps">
                                <div class="form-floating">
                                    <input v-model = "jmlcutiyangdiambil" type="text" id="jmlcutiyangdiambil" class="form-control form-control-lg w-100 rounded" placeholder="" autocomplete="off">
                                    <label for="jmlcutiyangdiambil" class="form-label text-secondary font07rem">Jumlah hari yang diambil</label>
                                </div>
                              </div>
                          </div>
                      </div>

                      <!--additionnal-luar-kota-->
                      <div v-if="txtform === '3'" class="alert alert-warning alert-dismissible border-danger border-5 border-start border-top-0 border-bottom-0 border-end-0 rounded-0 fade show" role="alert">
                        <div class="font08rem">Additional Info :</div> 
                        <hr class="my-1">
                        <div class="font08rem flex-box flexrow">
                            <i class="bi bi-check font08rem text-secondary"></i>
                            <span class="margin-left-05">Di-isi jika driver karyawan.
                            </span>
                        </div>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                      </div>
                      <div v-if="txtform === '3'" class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Tujuan terjauh</label>
                              <div class="w-100-ps">
                                <div class="form-floating">
                                    <input v-model = "tujuanterjauh" type="text" id="tujuanterjauh" class="form-control form-control-lg w-100 rounded" placeholder="" autocomplete="off">
                                    <label for="tujuanterjauh" class="form-label text-secondary font07rem">Tujuan terjauh</label>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div v-if="txtform === '3'" class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;Driver</label>
                              <div class="w-100-ps">
                                <div class="form-floating">
                                    <input v-model = "driversiapa" type="text" id="driversiapa" class="form-control form-control-lg w-100 rounded" placeholder="" autocomplete="off">
                                    <label for="driversiapa" class="form-label text-secondary font07rem">Driver</label>
                                </div>
                              </div>
                          </div>
                      </div>
                      <div v-if="txtform === '3'" class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                          <div class="grow-custom-1 w-100-ps flex-box flexcol ai-start padding-bottom-05">
                              <label class="form-label text-dark font10rem w-100-ps"><i class="bi bi-check"></i>&nbsp;No. Surat Tugas</label>
                              <div class="w-100-ps">
                                <div class="form-floating">
                                    <input v-model = "nomorst" type="text" id="nomorst" class="form-control form-control-lg w-100 rounded" placeholder="" autocomplete="off">
                                    <label for="nomorst" class="form-label text-secondary font07rem">No. Surat Tugas</label>
                                </div>
                              </div>
                          </div>
                      </div>

                      <template v-if="((checkTxtForm != false) && 
                                        (checkTxtNama != false) &&
                                        (checkTxtNomorLength != false) &&
                                        (checkTxtNomor != false) &&
                                        (checkTxtJabatan != false) && 
                                        (checkTxtStart != false) &&
                                        (checkTxtFinish != false) &&
                                        (checkTxtKeterangan != false))">
                            <div class="padding-custom-05 flex-box flexcol bg-light rounded margin-bottom-05">
                                <span class="text-secondary font10rem"><i class="bi bi-question-diamond"></i>&nbsp;Jawab pertanyaan berikut ini :  *</span>
                                <span class="text-dark font1050rem font-bold">{{ number01 }}&nbsp;+&nbsp;{{ number02 }}&nbsp;=</span>
                                <input v-model="textkodex1" type="hidden" class="form-control form-control-sm w-100-ps" placeholder="">
                                <input v-model="textkodex2" type="text" class="form-control form-control-lg w-100-ps" placeholder="Isikan jawaban disini">
                            </div>   
                            <div class="flex-box flexrow padding-top-1 padding-bottom-1">
                                <button @click="loadedOnce" type="button" class="btn btn-lg btn-secondary margin-right-05">
                                    Reset
                                </button>
                                <button @click="sendMe" type="button" class="btn btn-lg btn-primary margin-right-05">
                                    Send
                                </button>
                            </div>
                      </template>
                  </div>
              </div>
          </div>
          <div id="foterpage" class="relative-pos w-100-ps-min flex-box flexcol ai-center">©{{ yearnow }} Fsm.Co.Id 
          </div>
      </div>
</template>

<script>

export default {
  name: 'App',
  components: 
  {
  },
  data() {
      return {
        myLogo : window.origin+'/img/FSM Delivering Right.png',
        yearnow : '',
        txtform : '',
        txtnama : '',
        txtnomor : '',
        txtstart : '',
        txtfinish : '',
        txtdetail11 : '',
        txtdetail21 : '',
        txtdetail31 : '',
        txtjabatan : '',
        txtjabatan2 : '',
        txtketerangan : '',
        txtphone : '+6287862101942',
        nmform : '',
        jeniskepentingan : '',
        datesend : '',
        number01 : '',
        number02 : '',
        textkodex1 : '',
        textkodex2 : '',
        validnum : /[0-9]/,
        tglterakhircuti : '',
        sisacutithnberjalan : '',
        jmlcutiyangdiambil : '',
        tujuanterjauh : '',
        driversiapa : '',
        nomorst : '',
        txtphonedirektur : '+6282242642666',
        txtphonemanager : '+6282242642666',
        txtphoneadministrasi : '+6281232325454',
        txtphoneteknik : '+6287734147991',
        txtphoneit : '+6287862101942',
        txtphonemarketing : '+6281232325454',
        txtphonegudang : '+6281232325454',
        txtphonespv : '',
        direkturtxtphone : '+6282242642666',
        wakildirekturtxtphone : '+6287734147991',
        manageritprtxtphone : '+6287862101942',
        manageroperasionalmarketingtxtphone : '+6281232325454',
        managerfinanceaccountingtxtphone : '+6281232325454',
        kepalaenjineeringtxtphone : '+6281225460317',
        kepalateknisitxtphone : '+6285920560866',
      }
  },
  created() {
    const rand01 = Math.floor(Math.random() * 10);
    const rand02 = Math.floor(Math.random() * 10);
    this.number01 = rand01;
    this.number02 = rand02;
    this.textkodex1 = this.number01 + this.number02;
  },
  mounted() {
      this.currentYear();
  },
  computed: {
        checkTxtForm() {
            return this.txtform.length > 0 ? true : false
        },
        checkTxtJenis11() {
            return this.txtdetail11.length > 0 ? true : false
        },
        checkTxtJenis21() {
            return this.txtdetail21.length > 0 ? true : false
        },
        checkTxtJenis31() {
            return this.txtdetail31.length > 0 ? true : false
        },
        checkTxtNama() {
            return this.txtnama.length > 0 ? true : false
        },
        checkTxtNomor() {
            return this.validnum.test(this.txtnomor) ? true : false

        },
        checkTxtNomorLength() {
            return this.txtnomor.length > 0 ? true : false

        },
        checkTxtJabatan() {
            return this.txtjabatan.length > 0 ? true : false
        },
        checkTxtStart() {
            return this.txtstart.length > 0 ? true : false
        },
        checkTxtFinish() {
            return this.txtfinish.length > 0 ? true : false
        },
        checkTxtKeterangan() {
            return this.txtketerangan.length > 0 ? true : false
        },

  },        
  methods : {
        currentYear() {
            let tglnow = new Date()
            this.yearnow = tglnow.getFullYear()
        }, 
        loadedOnce(){
            localStorage.removeItem('reloaded');
            if (localStorage.getItem('reloaded')) {
                localStorage.removeItem('reloaded');
            } else {
                localStorage.setItem('reloaded', '1');
                location.reload();
            }
        },
        currentDate() {
            let tglnow = new Date()
            let thn1 = tglnow.getFullYear()
            let bln1 = tglnow.getMonth()+1
            let tgl1 = tglnow.getDate()
            let bln2 = bln1
            let tgl2 = tgl1

            if (bln1 < 10)
            {
                bln2 = '0'+bln1
            }
            else
            {
                bln2 = bln1
            }
            
            if (tgl2 < 10)
            {
                tgl2 = '0'+tgl1
            }
            else
            {
                tgl2 = tgl1
            }
            
            let date = thn1+''+bln2+''+tgl2
            return date
        },      
        currentTime() {
            let timenow = new Date()
            let hour1 = timenow.getHours()
            let minute1 = timenow.getMinutes()
            let hour2 = hour1
            let minute2 = minute1

            if (hour1 < 10)
            {
                hour2 = '0'+hour1
            }
            else
            {
                hour2 = hour1
            }
            
            if (minute1 < 10)
            {
                minute2 = '0'+minute1
            }
            else
            {
                minute2 = minute1
            }
            
            let time = hour2+''+minute2
            return time
        },            
        getNow(){
            let tglnow = new Date()
            let tgl1 = tglnow.getDate()
            let tgl2 = tgl1

            if (tgl2 < 10)
            {
                tgl2 = '0'+tgl1
            }
            else
            {
                tgl2 = tgl1
            }

            let key = (btoa(this.currentDate()+''+this.currentTime()+'FSM'+tgl2))+'1'
            //let xdecode = (atob((key).slice(0, -1)))
            this.datesend = key
        }, 

        sendMe(){
            this.getNow()
            let formname = this.txtform
            let namapegawai = this.txtnama
            let nomorpegawai = this.txtnomor
            let tglstart = this.txtstart
            let tglfinish = this.txtfinish
            let keterangan = this.txtketerangan
            let ijin = this.txtdetail11
            let lembur = this.txtdetail21
            let luarkota = this.txtdetail31
            let number01 = this.textkodex1
            let number02 = this.textkodex2
            let number03 = number02 - number01
            let jabatanpegawai = this.txtjabatan

            if (this.txtform.length > 0 && 
                    this.txtnama.length > 0 && 
                    this.txtnomor != 0 &&
                    this.txtjabatan.length > 0 &&
                    this.txtstart.length > 0 && 
                    this.txtfinish.length > 0 && 
                    this.txtketerangan.length > 0)
            {
                if ((this.txtdetail11.length < 1) && (this.txtdetail21.length < 1) && (this.txtdetail31.length < 1))
                {
                    alert('FAILED. Inputan tidak lengkap.')
                }
                else
                {
                    if (this.textkodex2.length > 0)
                    {
                        if (number03 != 0)
                        {
                            alert('FAILED. Jawaban salah.')
                        }
                        else
                        {
                            if ((ijin.length > 0) && (lembur.length < 1) && (luarkota.length < 1))
                            {
                                this.jeniskepentingan = ijin
                            }
                            else if ((ijin.length < 1) && (lembur.length > 0) && (luarkota.length < 1))
                            {
                                this.jeniskepentingan = lembur
                            }
                            else if ((ijin.length < 1) && (lembur.length < 1) && (luarkota.length > 0))
                            {
                                this.jeniskepentingan = luarkota
                            }
                            else
                            {
                                this.jeniskepentingan = ''
                            }

                            let thmulai = tglstart.substring(0,4)
                            let blmulai = tglstart.substring(5,7)
                            let tgmulai = tglstart.substring(8,10)
                            let jammulai = tglstart.substring(11,16)
                            let mulai = tgmulai+'-'+blmulai+'-'+thmulai+' '+jammulai 

                            let thfinish = tglfinish.substring(0,4)
                            let blfinish = tglfinish.substring(5,7)
                            let tgfinish = tglfinish.substring(8,10)
                            let jamfinish = tglfinish.substring(11,16)
                            let finish = tgfinish+'-'+blfinish+'-'+thfinish+' '+jamfinish 
                            /*
                            if (jabatanpegawai === 'Direktur')
                            {
                                this.txtphonespv = this.txtphonedirektur
                            }
                            else if (jabatanpegawai === 'Manajer')
                            {
                                this.txtphonespv = this.txtphonemanager
                            }
                            else if (jabatanpegawai === 'Administrasi')
                            {
                                this.txtphonespv = this.txtphoneadministrasi
                            }
                            else if (jabatanpegawai === 'Teknik')
                            {
                                this.txtphonespv = this.txtphoneteknik
                            }
                            else if (jabatanpegawai === 'IT')
                            {
                                this.txtphonespv = this.txtphoneit
                            }
                            else if (jabatanpegawai === 'Marketing')
                            {
                                this.txtphonespv = this.txtphonemarketing
                            }
                            else
                            {
                                this.txtphonespv = this.txtphonegudang
                            }
                            */

                            if (jabatanpegawai === 'direktur')
                            {
                                this.txtphonespv = this.direkturtxtphone
                            }
                            else if (jabatanpegawai === 'wakildirektur')
                            {
                                this.txtphonespv = this.wakildirekturtxtphone
                            }
                            else if (jabatanpegawai === 'manageritpr')
                            {
                                this.txtphonespv = this.manageritprtxtphone
                            }
                            else if (jabatanpegawai === 'manageroperasionalmarketing')
                            {
                                this.txtphonespv = this.manageroperasionalmarketingtxtphone
                            }
                            else if (jabatanpegawai === 'managerfinanceaccounting')
                            {
                                this.txtphonespv = this.managerfinanceaccountingtxtphone
                            }
                            else if (jabatanpegawai === 'kepalaenjineering')
                            {
                                this.txtphonespv = this.manageroperasionalmarketingtxtphone
                            }
                            else if (jabatanpegawai === 'kepalateknisi')
                            {
                                this.txtphonespv = this.manageroperasionalmarketingtxtphone
                            }
							else if (jabatanpegawai === 'stafffinanceaccounting')
                            {
                                this.txtphonespv = this.managerfinanceaccountingtxtphone
                            }
							else if (jabatanpegawai === 'staffit')
                            {
                                this.txtphonespv = this.manageritprtxtphone
                            }
							else if (jabatanpegawai === 'staffenjineeringelektronika')
                            {
                                this.txtphonespv = this.kepalaenjineeringtxtphone
                            }
							else if (jabatanpegawai === 'staffteknisielektrikal')
                            {
                                this.txtphonespv = this.kepalateknisitxtphone
                            }
							else if (jabatanpegawai === 'staffteknisiteknikal')
                            {
                                this.txtphonespv = this.kepalateknisitxtphone
                            }
							else if (jabatanpegawai === 'staffmarketing')
                            {
                                this.txtphonespv = this.manageroperasionalmarketingtxtphone
                            }
                            else
                            {
                                this.txtphonespv = this.manageroperasionalmarketingtxtphone
                            }

                            if (jabatanpegawai === 'direktur')
                            {
                                this.txtjabatan2 = 'Direktur'
                            }
                            else if (jabatanpegawai === 'wakildirektur')
                            {
                                this.txtjabatan2 = 'Wakil Direktur'
                            }
                            else if (jabatanpegawai === 'manageritpr')
                            {
                                this.txtjabatan2 = 'Manager IT dan PR'
                            }
                            else if (jabatanpegawai === 'manageroperasionalmarketing')
                            {
                                this.txtjabatan2 = 'Manager Operasional dan Marketing'
                            }
                            else if (jabatanpegawai === 'managerfinanceaccounting')
                            {
                                this.txtjabatan2 = 'Manager Finance dan Accounting'
                            }
                            else if (jabatanpegawai === 'kepalaenjineering')
                            {
                                this.txtjabatan2 = 'Kepala Enjineering'
                            }
                            else if (jabatanpegawai === 'kepalateknisi')
                            {
                                this.txtjabatan2 = 'Kepala Teknisi'
                            }
							else if (jabatanpegawai === 'stafffinanceaccounting')
                            {
                                this.txtjabatan2 = 'Staff Finance dan Accounting'
                            }
							else if (jabatanpegawai === 'staffit')
                            {
                                this.txtjabatan2 = 'Staff IT'
                            }
							else if (jabatanpegawai === 'staffenjineeringelektronika')
                            {
                                this.txtjabatan2 = 'Staff Enjineering dan Elektronika'
                            }
							else if (jabatanpegawai === 'staffteknisielektrikal')
                            {
                                this.txtjabatan2 = 'Staff Teknisi dan Elektrikal'
                            }
							else if (jabatanpegawai === 'staffteknisiteknikal')
                            {
                                this.txtjabatan2 = 'Staff Teknisi dan Teknikal'
                            }
							else if (jabatanpegawai === 'staffmarketing')
                            {
                                this.txtjabatan2 = 'Staff Marketing'
                            }
                            else
                            {
                                this.txtjabatan2 = 'Staff Gudang'
                            }


                            if (formname === '1')
                            {
                                this.nmform = 'IJIN'
                                if (this.txtdetail11 === 'Cuti')
                                {
                                    let txtrequest = "https://wa.me/"+this.txtphonespv+"?text=" +
                                                    "Form Request : " +"*"+ this.nmform +"*"+ "%0a" +
                                                    "Jenis : " +"*"+ this.jeniskepentingan.toUpperCase() +"*"+ "%0a%0a" +
                                                    "Nama Pegawai : " + namapegawai + "%0a" +
                                                    "Nomor Pegawai : " + nomorpegawai + "%0a" +
                                                    "Jabatan : " + this.txtjabatan2 + "%0a" +
                                                    "Tanggal Mulai : " + mulai + "%0a" +
                                                    "Tanggal Selesai : " + finish + "%0a" +
                                                    "Tanggal Terakhir Cuti : " + this.tglterakhircuti + "%0a" +
                                                    "Sisa Cuti Tahun Berjalan : " + this.sisacutithnberjalan + "%0a" +
                                                    "Jumlah Cuti yang Diambil : " + this.jmlcutiyangdiambil + "%0a" +
                                                    "Keterangan : " +"*"+ keterangan +"*"+ "%0a%0a" + this.datesend + "%0a";

                                    window.open(txtrequest, '_blank')
                                }
                                else
                                {
                                    let txtrequest = "https://wa.me/"+this.txtphonespv+"?text=" +
                                                    "Form Request : " +"*"+ this.nmform +"*"+ "%0a" +
                                                    "Jenis : " +"*"+ this.jeniskepentingan.toUpperCase() +"*"+ "%0a%0a" +
                                                    "Nama Pegawai : " + namapegawai + "%0a" +
                                                    "Nomor Pegawai : " + nomorpegawai + "%0a" +
                                                    "Jabatan : " + this.txtjabatan2 + "%0a" +
                                                    "Tanggal Mulai : " + mulai + "%0a" +
                                                    "Tanggal Selesai : " + finish + "%0a" +
                                                    "Keterangan : " +"*"+ keterangan +"*"+ "%0a%0a" + this.datesend + "%0a";

                                    window.open(txtrequest, '_blank')
                                }
                            }
                            else if (formname === '2')
                            {
                                this.nmform = 'LEMBUR'
                                let txtrequest = "https://wa.me/"+this.txtphonespv+"?text=" +
                                                "Form Request : " +"*"+ this.nmform +"*"+ "%0a" +
                                                "Jenis : " +"*"+ this.jeniskepentingan.toUpperCase() +"*"+ "%0a%0a" +
                                                "Nama Pegawai : " + namapegawai + "%0a" +
                                                "Nomor Pegawai : " + nomorpegawai + "%0a" +
                                                "Jabatan : " + this.txtjabatan2 + "%0a" +
                                                "Tanggal Mulai : " + mulai + "%0a" +
                                                "Tanggal Selesai : " + finish + "%0a" +
                                                "Keterangan : " +"*"+ keterangan +"*"+ "%0a%0a" + this.datesend + "%0a";

                                window.open(txtrequest, '_blank')                            }
                            else
                            {
                                this.nmform = 'LUAR KOTA'
                                let txtrequest = "https://wa.me/"+this.txtphonespv+"?text=" +
                                                "Form Request : " +"*"+ this.nmform +"*"+ "%0a" +
                                                "Jenis : " +"*"+ this.jeniskepentingan.toUpperCase() +"*"+ "%0a%0a" +
                                                "Nama Pegawai : " + namapegawai + "%0a" +
                                                "Nomor Pegawai : " + nomorpegawai + "%0a" +
                                                "Jabatan : " + this.txtjabatan2 + "%0a" +
                                                "Tanggal Mulai : " + mulai + "%0a" +
                                                "Tanggal Selesai : " + finish + "%0a" +
                                                "Tujuan Terjauh : " + this.tujuanterjauh + "%0a" +
                                                "Driver : " + this.driversiapa + "%0a" +
                                                "No. Surat Tugas : " + this.nomorst + "%0a" +
                                                "Keterangan : " +"*"+ keterangan +"*"+ "%0a%0a" + this.datesend + "%0a";

                                window.open(txtrequest, '_blank')
                            }
                            this.loadedOnce()
                        }
                    }
                    else
                    {
                        alert('FAILED. Tidak menjawab pertanyaan.')
                    }
                }
            }
            else
            {
                alert('FAILED. Inputan tidak lengkap.')
            }
        },
  }        

}
</script>